export default [
  {
    path: '*',
    meta: { public: true },
    name: '*',
    redirect: {
      path: '/login'
    }
  },
  {
    path: '/login',
    meta: { public: true },
    name: 'AuthLogin',
    component: () => import('@/views/auth/Login.vue')
  },
  {
    path: '/perfil',
    name: 'Perfil',
    meta: { allow: ['admin', 'user', 'auditor'], userTypes: ['master', 'gestor', 'usuario'] },
    component: () => import('@/views/auth/Perfil.vue')
  },
  {
    path: '/dashboard',
    name: 'DashboardView',
    meta: { audience: 'dashboard', allow: ['admin', 'user', 'auditor'], userTypes: ['master', 'gestor', 'usuario'] },
    component: () => import('@/views/dashboard/Dashboard.vue')
  },

  // Cadastros Básicos
  {
    path: '/area-campo',
    name: 'AreaCampoLista',
    meta: { audience: 'area-campo', allow: ['admin', 'user'], userTypes: ['gestor'], unidadePermissions: ['corporativa'] },
    component: () => import('@/views/area-campo/Lista.vue')
  },
  {
    path: '/atividade',
    name: 'AtividadeLista',
    meta: { audience: 'atividade', allow: ['admin'] },
    component: () => import('@/views/atividade/Lista.vue')
  },
  {
    path: '/pais',
    name: 'PaisLista',
    meta: { audience: 'pais', allow: ['admin'] },
    component: () => import('@/views/pais/Lista.vue')
  },
  {
    path: '/estado',
    name: 'EstadoLista',
    meta: { audience: 'estado', allow: ['admin'] },
    component: () => import('@/views/estado/Lista.vue')
  },
  {
    path: '/cidade',
    name: 'CidadeLista',
    meta: { audience: 'cidade', allow: ['admin'] },
    component: () => import('@/views/cidade/Lista.vue')
  },
  {
    path: '/competencia',
    name: 'CompetenciaLista',
    meta: { audience: 'competencia', allow: ['admin', 'user'], userTypes: ['gestor', 'master'], unidadePermissions: ['corporativa'] },
    component: () => import('@/views/competencia/Lista.vue')
  },
  {
    path: '/escopo',
    name: 'EscopoLista',
    meta: { audience: 'escopo', allow: ['admin'] },
    component: () => import('@/views/escopo/Lista.vue')
  },
  {
    path: '/impacto',
    name: 'ImpactoLista',
    meta: { audience: 'impacto', allow: ['admin'] },
    component: () => import('@/views/impacto/Lista.vue')
  },
  {
    path: '/orgao',
    name: 'OrgaoLista',
    meta: { audience: 'orgao', allow: ['admin', 'user'], userTypes: ['gestor', 'master'], unidadePermissions: ['corporativa'] },
    component: () => import('@/views/orgao/Lista.vue')
  },
  {
    path: '/oportunidade',
    name: 'OportunidadeLista',
    meta: { audience: 'oportunidade', allow: ['admin'] },
    component: () => import('@/views/oportunidade/Lista.vue')
  },
  {
    path: '/tema',
    name: 'TemaLista',
    meta: { audience: 'tema', allow: ['admin', 'user'], userTypes: ['gestor', 'master'], unidadePermissions: ['corporativa'] },
    component: () => import('@/views/tema/Lista.vue')
  },
  {
    path: '/tipo-documento',
    name: 'TipoLegislacaoLista',
    meta: { audience: 'tipo-legislacao', allow: ['admin', 'user'], userTypes: ['gestor', 'master'], unidadePermissions: ['corporativa'] },
    component: () => import('@/views/tipo-legislacao/Lista.vue')
  },
  {
    path: '/risco',
    name: 'RiscoLista',
    meta: { audience: 'risco', allow: ['admin'] },
    component: () => import('@/views/risco/Lista.vue')
  },
  {
    path: '/categoria-fale-conosco',
    name: 'CategoriaFaleConoscoLista',
    meta: { audience: 'fale-conosco', allow: ['admin'] },
    component: () => import('@/views/categoria-fale-conosco/Lista.vue')
  },

  // Empresas
  {
    path: '/empresa',
    name: 'Empresa',
    meta: { audience: 'empresa', allow: ['admin'] },
    component: { template: '<router-view/>' },
    redirect: {
      path: '/empresa'
    },
    children: [
      {
        path: '/empresa',
        name: 'EmpresaLista',
        meta: { audience: 'empresa', allow: ['admin'] },
        component: () => import('@/views/empresa/Lista.vue')
      },
      {
        path: '/empresa/cadastro',
        name: 'EmpresaCadastro',
        meta: { audience: 'empresa', allow: ['admin'] },
        component: () => import('@/views/empresa/Form.vue')
      },
      {
        path: '/empresa/cadastro/:id',
        name: 'EmpresaEditar',
        meta: { audience: 'empresa', allow: ['admin'] },
        component: () => import('@/views/empresa/Form.vue')
      },
      {
        path: '/empresa/:id/usuario/cadastro',
        name: 'EmpresaUsuarioCadastro',
        meta: { audience: 'empresa', allow: ['admin'] },
        component: () => import('@/views/empresa/UsuarioForm.vue')
      },
      {
        path: '/empresa/:id/usuario/cadastro/:usuario_id',
        name: 'EmpresaUsuarioEditar',
        meta: { audience: 'empresa', allow: ['admin'] },
        component: () => import('@/views/empresa/UsuarioForm.vue')
      }
    ]
  },
  {
    path: '/unidade',
    name: 'Unidade',
    meta: { audience: 'unidade' },
    component: { template: '<router-view/>' },
    redirect: {
      path: '/unidade'
    },
    children: [
      {
        path: '/unidade',
        name: 'UnidadeLista',
        meta: { audience: 'unidade', allow: ['admin'] },
        component: () => import('@/views/unidade/Lista.vue')
      },
      {
        path: '/unidade/cadastro',
        name: 'UnidadeCadastro',
        meta: { audience: 'unidade', allow: ['admin'] },
        component: () => import('@/views/unidade/Form.vue')
      },
      {
        path: '/unidade/cadastro/:id',
        name: 'UnidadeEditar',
        meta: { audience: 'unidade', allow: ['admin'] },
        component: () => import('@/views/unidade/Form.vue')
      },
      {
        path: '/unidade/:id/usuario/cadastro',
        name: 'UnidadeUsuarioCadastro',
        meta: { audience: 'unidade', allow: ['admin'] },
        component: () => import('@/views/unidade/UsuarioForm.vue')
      },
      {
        path: '/unidade/:id/usuario/cadastro/:usuario_id',
        name: 'UnidadeUsuarioEditar',
        meta: { audience: 'unidade', allow: ['admin'] },
        component: () => import('@/views/unidade/UsuarioForm.vue')
      },
      {
        path: '/unidade/:id/atividade',
        name: 'UnidadeAtividade',
        meta: { audience: 'unidade', allow: ['admin'] },
        component: () => import('@/views/unidade/Atividade.vue')
      },
      {
        path: '/unidade/:id/setor-area',
        name: 'UnidadeSetorArea',
        meta: { audience: 'unidade', allow: ['admin'] },
        component: () => import('@/views/unidade/SetorArea.vue')
      },
      {
        path: '/unidade/:id/setor-area/cadastro',
        name: 'UnidadeSetorAreaCadastro',
        meta: { audience: 'unidade', allow: ['admin'] },
        component: () => import('@/views/unidade/SetorForm.vue')
      },
      {
        path: '/unidade/:id/setor-area/cadastro/:setor_area_id',
        name: 'UnidadeSetorAreaEditar',
        meta: { audience: 'unidade', allow: ['admin'] },
        component: () => import('@/views/unidade/SetorForm.vue')
      },
      //planilhas
      {
        path: '/unidade/:id/aspectos-impactos',
        name: 'UnidadeAspectosImpactos',
        meta: { audience: 'unidade', allow: ['admin'] },
        component: () => import('@/views/unidade/AspectosImpactos.vue')
      },
      {
        path: '/unidade/:id/perigos-riscos',
        name: 'UnidadePerigosRiscos',
        meta: { audience: 'unidade', allow: ['admin'] },
        component: () => import('@/views/unidade/PerigosRiscos.vue')
      },
      {
        path: '/unidade/:id/riscos-oportunidades',
        name: 'UnidadeRiscosOportunidades',
        meta: { audience: 'unidade', allow: ['admin'] },
        component: () => import('@/views/unidade/RiscosOportunidades.vue')
      },

      {
        path: '/unidade/:id/processo/:tipo_processo/cadastro',
        name: 'UnidadeProcessoCadastro',
        meta: { audience: 'unidade', allow: ['admin'] },
        component: () => import('@/views/unidade/ProcessoForm.vue')
      },
      {
        path: '/unidade/:id/processo/:tipo_processo/cadastro/:processo_id',
        name: 'UnidadeProcessoEditar',
        meta: { audience: 'unidade', allow: ['admin'] },
        component: () => import('@/views/unidade/ProcessoForm.vue')
      },
      {
        path: '/unidade/:id/processo/:tipo_processo/planilha',
        name: 'UnidadeProcessoPlanilha',
        meta: { audience: 'unidade', allow: ['admin'] },
        component: () => import('@/views/unidade/ProcessoSheet.vue')
      },
      {
        path: '/unidade/:id/gdl/cadastro',
        name: 'UnidadeCorporativaCadastro',
        meta: { audience: 'unidade', allow: ['admin'] },
        component: () => import('@/views/unidade/CorporativaForm.vue')
      },
      {
        path: '/unidade/:id/gdl/cadastro/:corporativa_id',
        name: 'UnidadeCorporativaEditar',
        meta: { audience: 'unidade', allow: ['admin'] },
        component: () => import('@/views/unidade/CorporativaForm.vue')
      },
    ]
  },

  // Administração
  {
    path: '/admin',
    name: 'AdminLista',
    meta: { audience: 'admin', allow: ['admin'] },
    component: () => import('@/views/admin/Lista.vue')
  },
  {
    path: '/setor-admin',
    name: 'SetorAdminLista',
    meta: { audience: 'setor-admin', allow: ['admin'] },
    component: () => import('@/views/setor-admin/Lista.vue')
  },


  // SILA
  {
    path: '/sila/aprovada',
    name: 'SilaAprovada',
    meta: { audience: 'sila-aprovada', allow: ['user'] },
  },
  {
    path: '/legislacao',
    name: 'Legislacao',
    meta: { audience: 'legislacao', allow: ['admin', 'user'], userTypes: ['master', 'gestor', 'usuario'] },
    component: { template: '<router-view/>' },
    redirect: {
      path: '/legislacao'
    },
    children: [
      {
        path: '/legislacao',
        name: 'LegislacaoLista',
        meta: { audience: 'legislacao', allow: ['admin', 'user'], userTypes: ['master', 'gestor', 'usuario'] },
        component: () => import('@/views/legislacao/Lista.vue')
      },
      {
        path: '/legislacao/cadastro',
        name: 'LegislacaoCadastro',
        meta: { audience: 'legislacao', allow: ['admin'] },
        component: () => import('@/views/legislacao/Form.vue')
      },
      {
        path: '/legislacao/cadastro/:id',
        name: 'LegislacaoEditar',
        meta: { audience: 'legislacao', allow: ['admin'] },
        component: () => import('@/views/legislacao/Form.vue')
      }
    ]
  },
  {
    path: '/sila/gestao',
    name: 'Sila',
    meta: { audience: 'sila-gestao', allow: ['admin'] },
    component: { template: '<router-view/>' },
    redirect: {
      path: '/sila/gestao'
    },
    children: [
      {
        path: '/sila/gestao',
        name: 'SilaLista',
        meta: { audience: 'sila-gestao', allow: ['admin'] },
        component: () => import('@/views/sila/Lista.vue')
      },
      {
        path: '/sila/gestao/cadastro',
        name: 'SilaCadastro',
        meta: { audience: 'sila-gestao', allow: ['admin'] },
        component: () => import('@/views/sila/Form.vue')
      },
      {
        path: '/sila/gestao/cadastro/:id',
        name: 'SilaEditar',
        meta: { audience: 'sila-gestao', allow: ['admin'] },
        component: () => import('@/views/sila/Form.vue')
      },
      {
        path: '/sila/gestao/cadastro/:id/legislacao/:sila_tipo',
        name: 'SilaLegislacao',
        meta: { audience: 'sila-gestao', allow: ['admin'] },
        component: () => import('@/views/sila/Legislacao.vue')
      },
    ]
  },
  {
    path: '/sila/aprovacao',
    name: 'SilaAprovacao',
    meta: { audience: 'sila-aprovacao', allow: ['admin'] },
    component: { template: '<router-view/>' },
    redirect: {
      path: '/sila/aprovacao'
    },
    children: [
      {
        path: '/sila/aprovacao',
        name: 'SilaAprovacaoLista',
        meta: { audience: 'sila-aprovacao', allow: ['admin'] },
        component: () => import('@/views/sila/AprovacaoLista.vue')
      },
      {
        path: '/sila/aprovacao/:id/previa',
        name: 'SilaAprovacaoPrevia',
        meta: { audience: 'sila-aprovacao', allow: ['admin'] },
        component: () => import('@/views/sila/AprovacaoPrevia.vue')
      },
    ]
  },
  {
    path: '/sila/relatorio',
    name: 'SilaRelatorio',
    meta: { audience: 'sila-relatorio', allow: ['admin', 'user'], userTypes: ['master', 'gestor'] },
    component: { template: '<router-view/>' },
    redirect: {
      path: '/sila/relatorio'
    },
    children: [
      {
        path: '/sila/relatorio',
        name: 'SilaRelatorioLista',
        meta: { audience: 'sila-relatorio', allow: ['admin', 'user'], userTypes: ['master', 'gestor'] },
        component: () => import('@/views/sila/RelatorioLista.vue')
      },
      {
        path: '/sila/relatorio/:id',
        name: 'SilaRelatorioAcesso',
        meta: { audience: 'sila-relatorio', allow: ['admin', 'user'], userTypes: ['master', 'gestor'] },
        component: () => import('@/views/sila/RelatorioAcesso.vue')
      },
    ]
  },
  {
    path: '/sila/verificacao',
    name: 'SilaVerificacao',
    meta: { audience: 'sila-verificacao', allow: ['admin', 'user'], userTypes: ['master', 'gestor', 'usuario'] },
    component: { template: '<router-view/>' },
    children: [
      {
        path: '/sila/verificacao',
        name: 'SilaVerificacaoLista',
        meta: { audience: 'sila-verificacao', allow: ['admin'] },
        component: () => import('@/views/sila/VerificacaoLista.vue')
      },
      {
        path: '/sila/:id/verificacao',
        name: 'SilaVerificacaoAcesso',
        meta: { audience: 'sila-verificacao', allow: ['admin', 'user'], userTypes: ['master', 'gestor', 'usuario'] },
        component: () => import('@/views/sila/VerificacaoAcesso.vue')
      },
      {
        path: '/sila/:id/verificacao/:verificacao_id/comentario',
        name: 'SilaVerificacaoComentarioLista',
        meta: { audience: 'sila-verificacao', allow: ['admin', 'user'], userTypes: ['master', 'gestor', 'usuario'] },
        component: () => import('@/views/sila/VerificacaoComentarioLista.vue')
      },
      {
        path: '/sila/:id/verificacao/:verificacao_id/comentario/:comentario_id/mensagem',
        name: 'SilaVerificacaoComentarioMensagemLista',
        meta: { audience: 'sila-verificacao', allow: ['admin', 'user'], userTypes: ['master', 'gestor', 'usuario'] },
        component: () => import('@/views/sila/VerificacaoComentarioMensagemLista.vue')
      },
      {
        path: '/sila/:id/verificacao/:verificacao_id/evidencia',
        name: 'SilaVerificacaoEvidenciaLista',
        meta: { audience: 'sila-verificacao', allow: ['admin', 'user'], userTypes: ['master', 'gestor', 'usuario'] },
        component: () => import('@/views/sila/VerificacaoEvidenciaLista.vue')
      },
      {
        path: '/sila/:id/verificacao/:verificacao_id/plano-acao',
        name: 'SilaVerificacaoPlanoAcaoLista',
        meta: { audience: 'sila-verificacao', allow: ['admin', 'user'], userTypes: ['master', 'gestor', 'usuario'] },
        component: () => import('@/views/sila/VerificacaoPlanoAcaoLista.vue')
      },
      {
        path: '/sila/:id/verificacao/:verificacao_id/plano-acao/cadastro',
        name: 'SilaVerificacaoPlanoAcaoCadastro',
        meta: { audience: 'sila-verificacao', allow: ['admin', 'user'], userTypes: ['master', 'gestor', 'usuario'] },
        component: () => import('@/views/sila/VerificacaoPlanoAcaoForm.vue')
      },
      {
        path: '/sila/:id/verificacao/:verificacao_id/plano-acao/cadastro/:plano_acao_id',
        name: 'SilaVerificacaoPlanoAcaoEditar',
        meta: { audience: 'sila-verificacao', allow: ['admin', 'user'], userTypes: ['master', 'gestor', 'usuario'] },
        component: () => import('@/views/sila/VerificacaoPlanoAcaoForm.vue')
      },
    ]
  },
  {
    path: '/sila/log',
    name: 'SilaLog',
    meta: { audience: 'sila-log', allow: ['admin', 'user'], userTypes: ['gestor'] },
    component: () => import('@/views/sila/Log.vue')
  },
  {
    path: '/sila/backup',
    name: 'SilaBackup',
    meta: { audience: 'sila-backup', allow: ['admin'] },
    component: () => import('@/views/sila/Backup.vue')
  },
  {
    path: '/atualizacao-leis',
    name: 'AtualizaçãoLeis',
    meta: { audience: 'atualizacao-leis', allow: ['admin'] },
    component: () => import('@/views/atualizacao-leis/Lista.vue')
  },
  {
    path: '/fale-conosco',
    name: 'FaleConosco',
    meta: { audience: 'fale-conosco', allow: ['admin', 'user'], userTypes: ['master', 'gestor', 'usuario'] },
    component: { template: '<router-view/>' },
    redirect: {
      path: '/fale-conosco'
    },
    children: [
      {
        path: '/fale-conosco',
        name: 'FaleConoscoLista',
        meta: { audience: 'fale-conosco', allow: ['admin', 'user'], userTypes: ['master', 'gestor', 'usuario'] },
        component: () => import('@/views/fale-conosco/Lista.vue')
      },
      {
        path: '/fale-conosco/:id/mensagem',
        name: 'FaleConoscoMensagem',
        meta: { audience: 'fale-conosco', allow: ['admin', 'user'], userTypes: ['master', 'gestor', 'usuario'] },
        component: () => import('@/views/fale-conosco/MensagemLista.vue')
      },
    ]
  },
  {
    path: '/usuario',
    name: 'Usuario',
    meta: { audience: 'usuario', allow: ['user'], userTypes: ['gestor'] },
    component: { template: '<router-view/>' },
    redirect: {
      path: '/usuario'
    },
    children: [
      {
        path: '/usuario',
        name: 'UsuarioLista',
        meta: { audience: 'usuario', allow: ['user'], userTypes: ['gestor'] },
        component: () => import('@/views/usuario/Lista.vue')
      },
      {
        path: '/usuario/cadastro',
        name: 'UsuarioCadastro',
        meta: { audience: 'usuario', allow: ['user'], userTypes: ['gestor'] },
        component: () => import('@/views/usuario/Form.vue')
      },
      {
        path: '/usuario/cadastro/:usuario_id',
        name: 'UsuarioEditar',
        meta: { audience: 'usuario', allow: ['user'], userTypes: ['gestor'] },
        component: () => import('@/views/usuario/Form.vue')
      }
    ]
  },
  {
    path: '/setor-area',
    name: 'SetorArea',
    meta: { audience: 'setor-area', allow: ['user'], userTypes: ['master', 'gestor'], unidadePermissions: ['aspectos_impactos', 'perigos_riscos', 'riscos_oportunidades'] },
    component: { template: '<router-view/>' },
    redirect: {
      path: '/setor-area'
    },
    children: [
      {
        path: '/setor-area',
        name: 'SetorAreaLista',
        meta: { audience: 'setor-area', allow: ['user'], userTypes: ['master', 'gestor'], unidadePermissions: ['aspectos_impactos', 'perigos_riscos', 'riscos_oportunidades']  },
        component: () => import('@/views/setor-area/Lista.vue')
      },
      {
        path: '/setor-area/cadastro',
        name: 'SetorAreaCadastro',
        meta: { audience: 'setor-area', allow: ['user'], userTypes: ['master', 'gestor'], unidadePermissions: ['aspectos_impactos', 'perigos_riscos', 'riscos_oportunidades']  },
        component: () => import('@/views/setor-area/Form.vue')
      },
      {
        path: '/setor-area/cadastro/:setor_area_id',
        name: 'SetorAreaEditar',
        meta: { audience: 'setor-area', allow: ['user'], userTypes: ['master', 'gestor'], unidadePermissions: ['aspectos_impactos', 'perigos_riscos', 'riscos_oportunidades']  },
        component: () => import('@/views/setor-area/Form.vue')
      }
    ]
  },
  {
    path: '/unidade/atividade',
    name: 'Atividade',
    meta: { audience: 'atividade', allow: ['user'], userTypes: ['master', 'gestor'], unidadePermissions: ['aspectos_impactos', 'perigos_riscos', 'riscos_oportunidades'] },
    component: { template: '<router-view/>' },
    redirect: {
      path: '/unidade/atividade'
    },
    children: [
      {
        path: '/unidade/atividade',
        name: 'UnidadeAtividadeLista',
        meta: { audience: 'atividade', allow: ['user'], userTypes: ['master', 'gestor'], unidadePermissions: ['aspectos_impactos', 'perigos_riscos', 'riscos_oportunidades']  },
        component: () => import('@/views/atividade/UnidadeLista.vue')
      }
    ]
  },
  //PLANILHAS ESPECÍFICAS - CLIENTE
  {
    path: '/planilha-especifica/aspectos-impactos',
    name: 'AspectosImpactos',
    meta: { audience: 'planilhas-especificas', allow: ['user'], userTypes: ['master', 'gestor'], unidadePermissions: ['aspectos_impactos'] },
    component: () => import('@/views/planilha-especifica/AspectosImpactos.vue')
  },
  {
    path: '/planilha-especifica/perigos-riscos',
    name: 'PerigosRiscos',
    meta: { audience: 'planilhas-especificas', allow: ['user'], userTypes: ['master', 'gestor'], unidadePermissions: ['perigos_riscos'] },
    component: () => import('@/views/planilha-especifica/PerigosRiscos.vue')
  },
  {
    path: '/planilha-especifica/riscos-oportunidades',
    name: 'RiscosOportunidades',
    meta: { audience: 'planilhas-especificas', allow: ['user'], userTypes: ['master', 'gestor'], unidadePermissions: ['riscos_oportunidades'] },
    component: () => import('@/views/planilha-especifica/RiscosOportunidades.vue')
  },
  {
    path: '/planilha-especifica/processo/:tipo_processo/cadastro',
    name: 'PlanilhaProcessoCadastro',
    meta: { audience: 'planilhas-especificas', allow: ['user'], userTypes: ['master', 'gestor'], unidadePermissions: ['aspectos_impactos', 'perigos_riscos', 'riscos_oportunidades']  },
    component: () => import('@/views/planilha-especifica/ProcessoForm.vue')
  },
  {
    path: '/planilha-especifica/processo/:tipo_processo/cadastro/:processo_id',
    name: 'PlanilhaProcessoEditar',
    meta: { audience: 'planilhas-especificas', allow: ['user'], userTypes: ['master', 'gestor'], unidadePermissions: ['aspectos_impactos', 'perigos_riscos', 'riscos_oportunidades']  },
    component: () => import('@/views/planilha-especifica/ProcessoForm.vue')
  },
  {
    path: '/planilha-especifica/:tipo_processo/planilha',
    name: 'ProcessoPlanilha',
    meta: { audience: 'planilhas-especificas', allow: ['user'], userTypes: ['master', 'gestor', 'usuario'], unidadePermissions: ['aspectos_impactos', 'perigos_riscos', 'riscos_oportunidades']  },
    component: () => import('@/views/planilha-especifica/Sheet.vue')
  },

  {
    path: '/gdl',
    name: 'Corporativa',
    meta: { audience: 'corporativa', allow: ['user'], userTypes: ['master', 'gestor', 'usuario'], unidadePermissions: ['corporativa'] },
    component: { template: '<router-view/>' },
    redirect: {
      path: '/gdl'
    },
    children: [
      {
        path: '/gdl',
        name: 'CorporativaLista',
        meta: { audience: 'corporativa', allow: ['user'], userTypes: ['master', 'gestor', 'usuario'], unidadePermissions: ['corporativa'] },
        component: () => import('@/views/corporativa/Lista.vue')
      },
      {
        path: '/gdl/cadastro',
        name: 'CorporativaCadastro',
        meta: { audience: 'corporativa', allow: ['user'], userTypes: ['master', 'gestor'], unidadePermissions: ['corporativa'] },
        component: () => import('@/views/corporativa/Form.vue')
      },
      {
        path: '/gdl/cadastro/:corporativa_id',
        name: 'CorporativaEditar',
        meta: { audience: 'corporativa', allow: ['user'], userTypes: ['master', 'gestor'], unidadePermissions: ['corporativa'] },
        component: () => import('@/views/corporativa/Form.vue')
      },
    ]
  },
  {
    path: '/aviso',
    name: 'Aviso',
    meta: { audience: 'aviso', allow: ['admin', 'user'], userTypes: ['master', 'gestor', 'usuario'] },
    component: { template: '<router-view/>' },
    redirect: {
      path: '/aviso'
    },
    children: [
      {
        path: '/aviso',
        name: 'AvisoLista',
        meta: { audience: 'aviso', allow: ['admin', 'user'], userTypes: ['master', 'gestor', 'usuario'] },
        component: () => import('@/views/aviso/Lista.vue')
      },
      {
        path: '/aviso/cadastro',
        name: 'AvisoCadastro',
        meta: { audience: 'aviso', allow: ['admin'], userTypes: [], unidadePermissions: ['aviso'] },
        component: () => import('@/views/aviso/Form.vue')
      },
      {
        path: '/aviso/cadastro/:id',
        name: 'AvisoEditar',
        meta: { audience: 'aviso', allow: ['admin'], userTypes: [], unidadePermissions: ['aviso'] },
        component: () => import('@/views/aviso/Form.vue')
      },
    ]
  },
  {
    path: '/plano-de-acao',
    name: 'PlanoAcao',
    meta: { audience: 'plano-de-acao', allow: ['admin', 'user'], userTypes: ['master', 'gestor'] },
    component: { template: '<router-view/>' },
    redirect: {
      path: '/plano-de-acao'
    },
    children: [
      {
        path: '/plano-de-acao',
        name: 'PlanoAcaoLista',
        meta: { audience: 'plano-de-acao', allow: ['admin', 'user'], userTypes: ['master', 'gestor'] },
        component: () => import('@/views/plano-de-acao/Lista.vue')
      },
      {
        path: '/plano-de-acao/cadastro',
        name: 'PlanoAcaoCadastro',
        meta: { audience: 'plano-de-acao', allow: ['admin', 'user'], userTypes: ['master', 'gestor'] },
        component: () => import('@/views/plano-de-acao/Form.vue')
      },
      {
        path: '/plano-de-acao/cadastro/:plano_acao_id',
        name: 'PlanoAcaoEditar',
        meta: { audience: 'plano-de-acao', allow: ['admin', 'user'], userTypes: ['master', 'gestor'] },
        component: () => import('@/views/plano-de-acao/Form.vue')
      },
    ]
  },
  {
    path: '/auditor',
    name: 'Auditor',
    meta: { audience: 'auditor', allow: ['admin'] },
    redirect: {
      path: '/auditor/usuario'
    },
    component: { template: '<router-view/>' },
    children:[
      {
        path: '/auditor/usuario',
        name: 'AuditorLista',
        meta: { audience: 'auditor', allow: ['admin'] },
        component: () => import('@/views/auditor/Lista.vue'),
      },
      {
        path: '/auditor/tema',
        name: 'AuditorTemaLista',
        meta: { audience: 'auditor-tema', allow: ['admin'] },
        component: () => import('@/views/auditor-tema/Lista.vue')
      },
      {
        path: '/auditor/tipo-arquivo',
        name: 'AuditorTipoArquivoLista',
        meta: { audience: 'auditor-tipo-arquivo', allow: ['admin'] },
        component: () => import('@/views/auditor-tipo-arquivo/Lista.vue')
      },
      {
        path: '/auditor/modelo-documento',
        name: 'AuditorModeloDocumentoLista',
        meta: { audience: 'auditor-modelo-documento', allow: ['admin'] },
        component: () => import('@/views/auditor-modelo-documento/Lista.vue')
      },
      {
        path: '/auditor/contato',
        name: 'AuditorContatoLista',
        meta: { audience: 'auditor-contato', allow: ['admin', 'auditor'] },
        component: () => import('@/views/auditor-contato/Lista.vue')
      },
      {
        path: '/auditor/contato/:id/mensagem',
        name: 'AuditorContatoMensagem',
        meta: { audience: 'auditor-contato', allow: ['admin', 'auditor'] },
        component: () => import('@/views/auditor-contato/MensagemLista.vue')
      },
      {
        path: '/auditor/dds',
        name: 'DDSLista',
        meta: { audience: 'auditor-dds', allow: ['admin'] },
        component: () => import('@/views/auditor-dds/Lista.vue')
      },
      {
        path: '/auditor/dds/cadastro',
        name: 'DDSCadastro',
        meta: { audience: 'auditor-dds', allow: ['admin'] },
        component: () => import('@/views/auditor-dds/Form.vue')
      },
      {
        path: '/auditor/dds/cadastro/:id',
        name: 'DDSEditar',
        meta: { audience: 'auditor-dds', allow: ['admin'] },
        component: () => import('@/views/auditor-dds/Form.vue')
      },
    ]
  },
  // {
  //   path: '/auditor/arquivo',
  //   name: 'AuditorArquivoLista',
  //   meta: { audience: 'auditor-arquivo', allow: ['auditor'] },
  //   component: () => import('@/views/auditor-arquivo/Lista.vue')
  // },
  {
    path: '/auditor/legislacao',
    name: 'AuditorLegislacaoLista',
    meta: { allow: ['auditor'] },
    component: () => import('@/views/legislacao/Lista.vue')
  },
  {
    path: '/relatorio',
    name: 'Relatorio',
    meta: { audience: 'relatorio-gerencial', allow: ['admin', 'user'], userTypes: ['master', 'gestor'] },
    redirect: {
      path: '/relatorio/requisito'
    },
    component: { template: '<router-view/>' },
    children:[
      {
        path: '/relatorio/requisito',
        name: 'RelatorioRequisito',
        meta: { audience: 'relatorio-gerencial', allow: ['admin', 'user'], userTypes: ['master', 'gestor'] },
        component: () => import('@/views/relatorio-gerencial/Requisito.vue'),
      },
      {
        path: '/relatorio/plano-de-acao',
        name: 'RelatorioPlanoAcao',
        meta: { audience: 'relatorio-gerencial', allow: ['admin', 'user'], userTypes: ['master', 'gestor'] },
        component: () => import('@/views/relatorio-gerencial/PlanoAcao.vue'),
      },
      {
        path: '/relatorio/gdl',
        name: 'RelatorioCorporativa',
        meta: { audience: 'relatorio-gerencial', allow: ['admin', 'user'], userTypes: ['master', 'gestor'] },
        component: () => import('@/views/relatorio-gerencial/Corporativa.vue'),
      },
      {
        path: '/relatorio/planilha-especifica',
        name: 'RelatorioPlanilhaEspecifica',
        meta: { audience: 'relatorio-gerencial', allow: ['admin', 'user'], userTypes: ['master', 'gestor'] },
        component: () => import('@/views/relatorio-gerencial/PlanilhaEspecifica.vue'),
      },
      {
        path: '/relatorio/unidades-legislacao',
        name: 'RelatorioUnidadesLegislacao',
        meta: { audience: 'relatorio-gerencial', allow: ['admin'] },
        component: () => import('@/views/relatorio-gerencial/LegislacaoUnidades.vue'),
      },
      {
        path: '/relatorio/legislacao-unidades',
        name: 'RelatorioLegislacaoUnidades',
        meta: { audience: 'relatorio-gerencial', allow: ['admin'] },
        component: () => import('@/views/relatorio-gerencial/UnidadesLegislacao.vue'),
      }
    ]
  },
]
