/* eslint-disable no-useless-escape */
import Vue from 'vue'
import { mapState, mapActions } from 'vuex'
import { includes, uniqBy } from 'lodash'
import S from 'string'
import diacriticLess from 'diacriticless'

import App from './App.vue'
import router from './router'
import store from './store'

import vuetify from './plugins/Vuetify'
import NProgress from './plugins/NProgress'
import './plugins/Vuebar'
import './plugins/Base'
import './plugins/Day'
import './plugins/VeeValidate'
import './plugins/VueTheMask'
import './plugins/VuetifyConfirm'
import './plugins/VueQuillEditor'

import './utils/filters'

Vue.config.productionTip = false

new Vue({
  vuetify,
  store,
  router,
  render: h => h(App),
  computed: {
    ...mapState(['user', 'loading', 'remember', 'unidade_selecionada', 'unidades_usuario', 'unidades_areas_campo_usuario', 'corporativas_areas_campo_usuario']),
    isAdmin () {
      return store?.getters?.role === 'admin'
    },
    isAuditor () {
      return store?.getters?.role === 'auditor'
    },
    isUser() {
      return store?.getters?.role === 'user'
    },
    role () {
      return store?.getters?.role || 'user'
    },
    userEnvironment () {
      if (window.location.host.indexOf('admin') > -1)
        return 'admin'
      else if (window.location.host.indexOf('auditor') > -1)
        return 'auditor'
      return 'user'
    },
    adminUrl () {
      return process.env.VUE_APP_ADMIN_URL
    },
    auditorUrl () {
      return process.env.VUE_APP_AUDITOR_URL
    },
    userUrl () {
      return process.env.VUE_APP_USER_URL
    },
    isProduction () {
      return process?.env?.VUE_APP_NODE_ENV === 'production'
    },
    isDevelopment () {
      return process?.env?.VUE_APP_NODE_ENV !== 'production'
    },
    silaAtual () {
      return store?.getters?.sila_atual
    },
    canDestroyData () {
      return this.isAdmin ? this?.user?.permissoes?.indexOf('destroy-data') > -1 : true
    },
    minDateGeneral () {
      return '1900-01-01'
    },
    maxDateGeneral () {
      return this.$dayjs().add(20, 'years').format('YYYY-MM-DD')
    },
    minDateGeneralRule () {
      return (value) => this.validateMinDateGeneral(value) || 'Data inválida'
    },
    maxDateGeneralRule () {
      return (value) => this.validateMaxDateGeneral(value) || 'Data inválida'
    },
  },
  watch: {
    loading (val) {
      NProgress[val ? 'start' : 'done']()
    }
  },
  methods: {
    ...mapActions({
      setUser: 'SET_USER',
      setToken: 'SET_TOKEN',
      setRole: 'SET_ROLE',
      setUnidadeSelecionada: 'SET_UNIDADE_SELECIONADA',
      setUnidadesUsuario: 'SET_UNIDADES_USUARIO',
      setUnidadesAreasCampoUsuario: 'SET_UNIDADES_AREAS_CAMPO_USUARIO',
      setCorporativasAreasCampoUsuario: 'SET_CORPORATIVAS_AREAS_CAMPO_USUARIO',
      setUnidadesBloqueadas: 'SET_UNIDADES_BLOQUEADAS',
      setAvisoPagamentoPendente: 'SET_AVISO_PAGAMENTO_PENDENTE',
      setRemember: 'SET_REMEMBER',
      resetStore: 'RESET_STORE'
    }),
    validateMinDateGeneral (value) {
      return !value || this.$dayjs(value).isAfter(this.$dayjs('1900-01-01', 'YYYY-MM-DD'))
    },
    validateMaxDateGeneral (value) {
      return !value || this.$dayjs(value).isBefore(this.$dayjs().add(20, 'years'))
    },
    slugify (string) {
      const a = 'àáäâãåăæçèéëêǵḧìíïîḿńǹñòóöôœøṕŕßśșțùúüûǘẃẍÿź·/_,:;';
      const b = 'aaaaaaaaceeeeghiiiimnnnooooooprssstuuuuuwxyz------';
      const p = new RegExp(a.split('').join('|'), 'g');
      return string.toString().toLowerCase()
        .replace(/\s+/g, '-') // Replace spaces with -
        .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
        .replace(/&/g, '-and-') // Replace & with ‘and’
        .replace(/[^\w\-]+/g, '') // Remove all non-word characters
        .replace(/\-\-+/g, '-') // Replace multiple - with single -
        .replace(/^-+/, '') // Trim - from start of text
        .replace(/-+$/, ''); // Trim - from end of text
    },
    removeAccentsAndSpecialChars (str) {
      return str && typeof str === 'string' ? S(diacriticLess(String(str))).stripPunctuation().s : ''
    },
    getDeepKeys (obj) {
      const vm = this
      let keys = {}
      for (let key in obj) {
        keys[key] = obj[key]
        if (typeof obj[key] === "object") {
          const subkeys = vm.getDeepKeys(obj[key])
          for (const prop of Object.keys(subkeys)) {
            keys[`${key}.${prop}`] = subkeys[prop]
          }
        }
      }
      return keys
    },
    customSearch (value, queryText, item) {
      const vm = this
      if (!queryText || queryText?.toString()?.trim() === '') return true
      item = vm.getDeepKeys(item)
      const array = new Array(Object.keys(item).length).fill(false)
      let index = 0
      for (const prop of Object.keys(item)) {
        array[index] = item[prop] ? item[prop].toString() ? vm.removeAccentsAndSpecialChars(item[prop].toString().toLowerCase()).indexOf(vm.removeAccentsAndSpecialChars(queryText.toLowerCase())) > -1 : item[prop].indexOf(queryText.toLowerCase()) > -1 : false
        index = index + 1
      }
      return includes(array, true)
    },
    customFilter (item, queryText) {
      const vm = this
      if (!queryText || queryText?.toString()?.trim() === '') return true
      item = vm.getDeepKeys(item)
      const array = new Array(Object.keys(item).length).fill(false)
      let index = 0
      for (const prop of Object.keys(item)) {
        array[index] = item[prop] ? item[prop].toString() ? vm.removeAccentsAndSpecialChars(item[prop].toString().toLowerCase()).indexOf(vm.removeAccentsAndSpecialChars(queryText.toLowerCase())) > -1 : item[prop].indexOf(queryText.toLowerCase()) > -1 : false
        index = index + 1
      }
      return includes(array, true)
    },
    canEditSILA (sila_legislacao, sila) {
      const vm = this
      const { unidade, unidade_id } = sila
      const { editar_sila } = unidade
      const { area_campo_id, sila_tipo } = sila_legislacao

      if (sila.status !== 'aprovada') {
        return false
      }
      if (vm.$root.isAdmin && editar_sila) {
        return true
      }
      const unidade_usuario = vm.unidades_usuario?.find(unidade_usuario => unidade_usuario.unidade_id === unidade_id)
      if (unidade_usuario?.tipo === 'usuario')  {
        return false
      }
      if (sila_tipo === 'corporativas') {
        if (unidade_usuario?.tipo === 'gestor') {
          return true
        }
        return !!vm.corporativas_areas_campo_usuario?.find(o => o?.area_campo_id === area_campo_id && o?.unidade_id === unidade_id && o.tipo !== 'usuario')
      }
      const unidade_area_campo_usuario = vm.unidades_areas_campo_usuario?.find(unidade_area_campo_usuario => unidade_area_campo_usuario.unidade_area_campo.unidade_id === unidade_id && unidade_area_campo_usuario.unidade_area_campo.area_campo_id === area_campo_id)
      return ['gestor', 'master'].includes(unidade_area_campo_usuario?.tipo)
    },
    canEditVerificacaoSILA (verificacao) {
      if (!verificacao?.id) {
        return false
      }
      const { sila_legislacao } = verificacao
      const { sila } = sila_legislacao
      return this.canEditSILA(sila_legislacao, sila)
    },
    getAreasCampoUsuario (usuario) {
      return uniqBy(usuario.unidades_areas_campo, o => o.unidade_area_campo.area_campo_id)
    },
    getTotalAreasCampoUsuario (usuario) {
      return this.getAreasCampoUsuario(usuario).length
    },
    pageFullScreen (routeName) {
      return [
        'SilaVerificacaoAcesso',
        'ProcessoPlanilha',
        'UnidadeProcessoPlanilha',
        'SilaVerificacaoEvidenciaLista',
        'SilaVerificacaoPlanoAcaoLista',
        'SilaVerificacaoPlanoAcaoCadastro',
      ].includes(routeName)
    }
  },
}).$mount('#app')
