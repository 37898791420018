const SET_USER = ({ commit }, payload) => commit('SET_USER', payload)

const SET_ROLE = ({ commit }, payload) => commit('SET_ROLE', payload)

const SET_TOKEN = ({ commit }, payload) => commit('SET_TOKEN', payload)

const SET_UNIDADE_SELECIONADA = ({ commit }, payload) => commit('SET_UNIDADE_SELECIONADA', payload)

const SET_UNIDADES_USUARIO = ({ commit }, payload) => commit('SET_UNIDADES_USUARIO', payload)

const SET_UNIDADES_AREAS_CAMPO_USUARIO = ({ commit }, payload) => commit('SET_UNIDADES_AREAS_CAMPO_USUARIO', payload)

const SET_CORPORATIVAS_AREAS_CAMPO_USUARIO = ({ commit }, payload) => commit('SET_CORPORATIVAS_AREAS_CAMPO_USUARIO', payload)

const SET_UNIDADES_BLOQUEADAS = ({ commit }, payload) => commit('SET_UNIDADES_BLOQUEADAS', payload)

const SET_AVISO_PAGAMENTO_PENDENTE = ({ commit }, payload) => commit('SET_AVISO_PAGAMENTO_PENDENTE', payload)

const SET_LOADING = ({ commit }, payload) => commit('SET_LOADING', payload)

const SET_SIDEBAR_DRAWER = ({ commit }, payload) => commit('SET_SIDEBAR_DRAWER', payload)

const SET_REMEMBER = ({ commit }, payload) => commit('SET_REMEMBER', payload)

const SET_AUTH_EXPIRED = ({ commit }, payload) => commit('SET_AUTH_EXPIRED', payload)

const SET_SILA_ATUAL = ({ commit }, payload) => commit('SET_SILA_ATUAL', payload)

const SET_COOKIE_ACCEPT = ({ commit }, payload) => commit('SET_COOKIE_ACCEPT', payload)

const RESET_STORE = ({ commit }) => {
  commit('SET_USER', null)
  commit('SET_TOKEN', null)
  commit('SET_UNIDADE_SELECIONADA', null)
  commit('SET_UNIDADES_USUARIO', [])
  commit('SET_UNIDADES_AREAS_CAMPO_USUARIO', [])
  commit('SET_CORPORATIVAS_AREAS_CAMPO_USUARIO', [])
  commit('SET_UNIDADES_BLOQUEADAS', false)
  commit('SET_AVISO_PAGAMENTO_PENDENTE', false)
  commit('SET_LOADING', false)
  commit('SET_SIDEBAR_DRAWER', true)
  commit('SET_AUTH_EXPIRED', false)
  commit('SET_SILA_ATUAL', null)
  commit('SET_COOKIE_ACCEPT', false)
}

export {
  SET_USER,
  SET_ROLE,
  SET_TOKEN,
  SET_UNIDADE_SELECIONADA,
  SET_UNIDADES_USUARIO,
  SET_UNIDADES_AREAS_CAMPO_USUARIO,
  SET_CORPORATIVAS_AREAS_CAMPO_USUARIO,
  SET_UNIDADES_BLOQUEADAS,
  SET_AVISO_PAGAMENTO_PENDENTE,
  SET_LOADING,
  SET_SIDEBAR_DRAWER,
  SET_REMEMBER,
  SET_AUTH_EXPIRED,
  RESET_STORE,
  SET_SILA_ATUAL,
  SET_COOKIE_ACCEPT,
}
